import revive_payload_client_92hHcfwPJH from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Gzq1Pls6o3 from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_LYCbx2dxcL from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WBfIAb1PDF from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_GFOFCTHO6U from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_UrhLkPQkKi from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0TfYHi4wNb from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/.nuxt/components.plugin.mjs";
import prefetch_client_9bN2ZDYTni from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt@3.13.0_@parcel+watcher@2.4.1_@types+node@20.11.19_eslint@8.57.0_ioredis@5.4.1_magicast@0_o3gzhkcsiwd6ui7w5y2zjb57ki/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_KGDUX7Sd51 from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@20.11.19_graphql-tag@2.12.6_grap_3khknrf6644zotte6jxbdzfhhy/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import composition_ETomOTnXLf from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.21.0_vue-router@4.4.3_vue@3.4.38_typescrip_ubby4hwqm2hklthzefnyqhfcoi/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_VZDSu59yzD from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.10_magicast@0.3.4_rollup@4.21.0_vue-router@4.4.3_vue@3.4.38_typescrip_ubby4hwqm2hklthzefnyqhfcoi/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import google_tag_manager_2R27v5gfAU from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/plugins/google-tag-manager.ts";
import sanitize_html_kIz0q5HPOS from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/plugins/sanitize-html.ts";
import sentry_client_dyxoAk6TFV from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/plugins/sentry.client.ts";
export default [
  revive_payload_client_92hHcfwPJH,
  unhead_Gzq1Pls6o3,
  router_LYCbx2dxcL,
  payload_client_WBfIAb1PDF,
  navigation_repaint_client_GFOFCTHO6U,
  check_outdated_build_client_UrhLkPQkKi,
  chunk_reload_client_0TfYHi4wNb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_9bN2ZDYTni,
  plugin_KGDUX7Sd51,
  composition_ETomOTnXLf,
  i18n_VZDSu59yzD,
  google_tag_manager_2R27v5gfAU,
  sanitize_html_kIz0q5HPOS,
  sentry_client_dyxoAk6TFV
]