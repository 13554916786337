import { default as _91_46_46_46pageSlug_93FpFHKm1sUFMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/[...pageSlug].vue?macro=true";
import { default as activate8fNKJLqDrZMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/activate.vue?macro=true";
import { default as indexe5eAnXvI1aMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/index.vue?macro=true";
import { default as _91securityKey_93tQQlvKj2V0Meta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue?macro=true";
import { default as newEDlrrfr6f7Meta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/password/new.vue?macro=true";
import { default as watchlistdhSGuur1EFMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/watchlist.vue?macro=true";
import { default as indexJLs8DKT2whMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/index.vue?macro=true";
import { default as infos6J6UtgPhLuMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/infos.vue?macro=true";
import { default as program80S0r7m4UzMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/program.vue?macro=true";
import { default as indextG12kbSJSxMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue?macro=true";
import { default as _91showSlug_93gwMTRQ3ah0Meta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue?macro=true";
import { default as _91movieSlug_934wRBqw0tihMeta } from "/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug].vue?macro=true";
export default [
  {
    name: "pageSlug___en",
    path: "/en/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___de",
    path: "/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "pageSlug___es",
    path: "/es/:pageSlug(.*)*",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/[...pageSlug].vue").then(m => m.default || m)
  },
  {
    name: "account-activate___en",
    path: "/en/account/activate",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___de",
    path: "/konto/aktivieren",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account-activate___es",
    path: "/es/cuenta/activar",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/activate.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___de",
    path: "/konto",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account___es",
    path: "/es/cuenta",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___en",
    path: "/en/account/order/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___de",
    path: "/konto/bestellung/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-order-orderNumber-securityKey___es",
    path: "/es/cuenta/compra/:orderNumber/:securityKey",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/order/[orderNumber]/[securityKey].vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___en",
    path: "/en/account/password/new",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___de",
    path: "/konto/passwort/neu",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-password-new___es",
    path: "/es/cuenta/contrasena/nueva",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/password/new.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___en",
    path: "/en/account/watchlist",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___de",
    path: "/konto/merkliste",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: "account-watchlist___es",
    path: "/es/cuenta/marcadores",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/layers/base/pages/account/watchlist.vue").then(m => m.default || m)
  },
  {
    name: _91movieSlug_934wRBqw0tihMeta?.name,
    path: "/en/movie/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___en",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___en",
    path: "/en/movie/:movieSlug/infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___en",
    path: "/en/movie/:movieSlug/program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93gwMTRQ3ah0Meta?.name,
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___en",
    path: "/en/movie/:movieSlug/show/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91movieSlug_934wRBqw0tihMeta?.name,
    path: "/film/:movieSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___de",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___de",
    path: "/film/:movieSlug/filminfos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___de",
    path: "/film/:movieSlug/spielzeiten",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93gwMTRQ3ah0Meta?.name,
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___de",
    path: "/film/:movieSlug/vorstellung/:showSlug",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91movieSlug_934wRBqw0tihMeta?.name,
    path: "/es/movie/:movieSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-infos___es",
    path: "infos",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/infos.vue").then(m => m.default || m)
  },
  {
    name: "movie-movieSlug-program___es",
    path: "program",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/program.vue").then(m => m.default || m)
  },
  {
    name: _91showSlug_93gwMTRQ3ah0Meta?.name,
    path: "show/:showSlug()",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug].vue").then(m => m.default || m),
    children: [
  {
    name: "movie-movieSlug-show-showSlug___es",
    path: "",
    component: () => import("/home/forge/deployments/sitefactory/live/2024-08-27_09-34-46_ce07e4a/apps/site-factory/pages/movie/[movieSlug]/show/[showSlug]/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]